<template>
  <div id="jci-program-create">
    <v-overlay :value="OverlayLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form3" v-model="valid3" lazy-validation>
      <v-stepper v-model="step">
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <br />
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card elevation="3" color="#3a67b1" v-if="MemeberFullname != ''">
              <v-card-text>
                <v-row wrap>
                  <v-col
                    align="center"
                    cols="12"
                    md="12"
                    class="mycard-title-2 fsize-5 fcolor-1"
                  >
                    <p style="color: white">
                      <b>Welcome {{ MemeberFullname }},</b>
                    </p>
                    <p style="color: white">
                      <b>{{ CurrentDesignation }}, {{ LomName }}</b>
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <br />
            <v-card elevation="3" outlined>
              <v-card-title
                class="mycard-title-1 fsize-4 fcolor-1 bg-color-2"
                style="background-color: #edbe38; color: white"
              >
                Select The Event You Wish To Host &nbsp;
              </v-card-title>
              <v-btn
                style="margin-left: 93%"
                to="/events-bidding/lo-list"
                color="#1db954"
                elevation="30"
                rounded
                class="font-size-h6 mr-3 my-3 white--text d-inline-flex align-center"
                >Back</v-btn
              >
              <br />
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> Event Name</h6>
                    </label>
                    <v-autocomplete
                      @change="getEventTypeDetails"
                      :reduce="(option) => option.value"
                      :loading="EventTypeOptionsLoading"
                      :items="EventTypeOptions"
                      :rules="EventTypeRules"
                      v-model="EventName"
                      hint="Select a event type"
                      persistent-hint
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span>Proposed From Date
                      </h6>
                    </label>
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          required
                          v-model="FromDate"
                          label="From Date"
                          hint="Select a From Date"
                          persistent-hint
                          readonly
                          v-on="on"
                          background-color="#F3F6F9"
                          class="form-control form-control-lg form-control-solid"
                          validate-on-blur
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="FromDate"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>Proposed To Date</h6>
                    </label>
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          required
                          v-model="ToDate"
                          label="To Date"
                          hint="Select a To Date"
                          persistent-hint
                          readonly
                          v-on="on"
                          background-color="#F3F6F9"
                          class="form-control form-control-lg form-control-solid"
                          validate-on-blur
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="ToDate"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br />
            <v-card elevation="3" outlined>
              <v-card-title
                class="mycard-title-1 fsize-4 fcolor-1 bg-color-2"
                style="background-color: #0097d7; color: white"
              >
                Details About The Venue Name &nbsp;
              </v-card-title>
              <br />
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Stage Type
                        <small>(optional)</small>
                      </h6>
                    </label>
                    <v-select
                      :items="StageTypeOptions"
                      v-model="StageType"
                      :rules="StageTypeRules"
                      required
                      outlined
                      dense
                      placeholder="--Select Options--"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>Proposed Venue <small>(optional)</small></h6>
                    </label>
                    <v-text-field
                      v-model="Venue"
                      :rules="VenueRules"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>Proposed Venue Type<small> (optional)</small></h6>
                    </label>
                    <v-text-field
                      v-model="VenueType"
                      :rules="VenueTypeRules"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>Hall Capacity<small> (optional)</small></h6>
                    </label>
                    <v-text-field
                      v-model="HallCapacity"
                      :rules="HallCapacityRules"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> State</h6>
                    </label>
                    <!-- append-outer-icon="mdi-refresh" -->
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="StateIdOptionsLoading"
                      :items="StateIdOptions"
                      :rules="StateIdRules"
                      v-model="StateId"
                      @click:append-outer="getStateIdOptions"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> District</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="DistrictIdOptionsLoading"
                      :items="DistrictIdOptions"
                      :rules="DistrictIdRules"
                      v-model="DistrictId"
                      @click:append-outer="getDistrictIdOptions"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> City</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="CityIdOptionsLoading"
                      :items="CityIdOptions"
                      :rules="CityIdRules"
                      v-model="CityName"
                      @click:append-outer="getCityIdOptions"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>Available Rooms<small>(optional)</small></h6>
                    </label>
                    <v-text-field
                      v-model="AvailableRooms"
                      :rules="AvailableRoomsRules"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>
                        Distance to the Hotel from Airport (Km).<small
                          >(optional)</small
                        >
                      </h6>
                    </label>
                    <v-text-field
                      v-model="AirportDistance"
                      :rules="AirportDistanceRules"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>
                        Distance to the Hotel from Railway Stn (Km).<small
                          >(optional)</small
                        >
                      </h6>
                    </label>
                    <v-text-field
                      v-model="TrainDistance"
                      :rules="TrainDistanceRules"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>
                        Distance to the Hotel from Bus station (km).<small
                          >(optional)</small
                        >
                      </h6>
                    </label>
                    <v-text-field
                      v-model="BusDistance"
                      :rules="BusDistanceRules"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br />
            <v-card elevation="3" outlined>
              <v-card-title
                class="mycard-title-1 fsize-4 fcolor-1 bg-color-2"
                style="background-color: #edbe38; color: white"
              >
                Resolution Of The LGB &nbsp;
              </v-card-title>
              <br />
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6><span class="text-danger">*</span>Date of LO LGB</h6>
                    </label>
                    <v-menu
                      v-model="menu3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          required
                          v-model="LgbHeldDate"
                          label="From Date"
                          hint="Select a LGB Held Date"
                          persistent-hint
                          readonly
                          v-on="on"
                          background-color="#F3F6F9"
                          class="form-control form-control-lg form-control-solid"
                          validate-on-blur
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="LgbHeldDate"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <h6 class="mb-2">Attach LGB Resolution</h6>
                    <v-file-input
                      v-model="UploadedFile1"
                      accept="image/png, image/jpeg, image/bmp, application/pdf"
                      label="Upload File"
                      :rules="UploadedFile1Rules"
                      hint="Select a file to upload"
                      persistent-hint
                      outlined
                      dense
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br />
            <v-card elevation="3" outlined>
              <v-card-title
                class="mycard-title-1 fsize-4 fcolor-1 bg-color-2"
                style="background-color: #2596be; color: white"
              >
                If Any Other Information &nbsp;
              </v-card-title>
              <br />
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <h6><span class="text-danger">*</span> Remarks</h6>
                    <v-textarea
                      v-model="Remarks"
                      :rules="RemarksRules"
                      label="Additional Comments"
                      hint="Provide any additional details here"
                      persistent-hint
                      outlined
                      dense
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-btn
                  class="w-25 mx-auto d-block"
                  :disabled="!valid1"
                  @click.prevent="confirmAlert(1)"
                  :loading="SubmitFlag"
                  color="#1db954"
                >
                  <span style="color: white"> Submit The Propsal</span>
                </v-btn>
              </v-container>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-form>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  data() {
    return {
      step: 1,
      valid1: true,
      valid2: true,
      valid3: true,
      valid4: true,
      valid5: true,
      OverlayLoadingFlag: false,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      SendOtpFlag: false,
      SendOtpLoadingFlag: false,
      VerifyOtpFlag: false,
      VerifyOtpLoadingFlag: false,
      ResendOtpFlag: false,

      MemberId: "",

      MemberName: "",

      // For Step 1

      SelectedEventType: {},

      EventTypeRules: [(v) => !!v || "Event Name is required"],
      EventName: "",
      EventTypeOptions: [],
      EventTypeOptionsLoading: false,

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      menu2: false,

      menu3: false,
      LgbHeldDateRules: [(v) => !!v || "Date of LO LGB  is required"],
      LgbHeldDate: "",

      CustomName: "",
      CustomNameRules: [],

      StageTypeOptions: [
        { value: 1, text: "Theatre Style" },
        { value: 2, text: "U-Shape-Style" },
        { value: 3, text: "Round-Table" },
      ],
      StageTypeRules: [(v) => !!v || "Stage Type is required"],
      StageType: "--Select Options--",
      seniorMemberTypeFlag: true,

      Venue: "",
      VenueRules: [],

      VenueType: "",
      VenueTypeRules: [],

      AvailableRooms: "",
      AvailableRoomsRule: [],

      AirportDistance: "",
      AirportDistanceRules: [],

      BusDistance: "",
      BusDistanceRules: [],

      TrainDistance: "",
      TrainDistanceRules: [],

      HallCapacity: "",
      HallCapacityRules: [],

      StateIdRules: [(v) => !!v || "State is required"],
      StateId: "",
      StateIdOptions: [],
      StateIdOptionsLoading: false,

      DistrictIdRules: [(v) => !!v || "District is required"],
      DistrictId: "",
      DistrictIdOptions: [],
      DistrictIdOptionsLoading: false,

      CityIdRules: [(v) => !!v || "City is required"],
      CityName: "",
      CityIdOptions: [],
      CityIdOptionsLoading: false,

      RegistrationTpe: [],

      RegistrationTpeOptions: [
        { value: 1, text: "JCI India Member" },
        { value: 2, text: "JCI India Senior Member" },
        { value: 3, text: "JCOM Member" },
        { value: 4, text: "Non-Member" },
      ],
      EligibilityResolution: "",
      EligibilityResolutionRules: [
        (v) => !!v || "Eligibility Resolution is required",
      ],

      Remarks: "",
      RemarksRules: [(v) => !!v || "Remarks in the LO LGB is required"],

      UploadedFile1: {},
      UploadedFile1Rules: [(v) => !!v || "File Upload is required"],

      CurrentDesignation: "",
      MemeberFullname: "",
      LomName: "",

      ParticipantZoneType: true,

      ParticipantZoneCodeRules: [],
      ParticipantZoneCode: [],
      ParticipantZoneCodeOptions: [(v) => !!v || "Zone is required"],
      ParticipantZoneCodeOptionsLoading: false,

      ParticipantLomType: true,

      ParticipantLomCodeRules: [],
      ParticipantLomCode: [],
      ParticipantLomCodeOptions: [(v) => !!v || "LO is required"],
      ParticipantLomCodeOptionsLoading: false,

      ParticipantNonMemberFlag: false,
      ParticipantNonMember: false,

      ParticipantAllDesignation: true,

      NationalDesigntionType: true,

      uploadPercentage: 0,
      rows: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
      RoleId: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var CurrentYearId = this.$session.get("CurrentYearId");
        CurrentYearId = 1;
        CurrentYearId =
          CurrentYearId == (null || undefined) ? 0 : CurrentYearId;
        this.CurrentYearId = CurrentYearId;

        var RoleId = this.$session.get("RoleId");
        RoleId = RoleId == (null || undefined) ? "" : RoleId;
        // RoleId = 4;
        this.RoleId = RoleId;

        var todayDate = new Date().toISOString().slice(0, 10);
        console.log(todayDate);

        this.FromDate = todayDate;
        this.ToDate = todayDate;
        this.LgbHeldDate = todayDate;

        this.getPageData();
        this.pageData();
      }
    },
    pageData() {
      console.log("pageData called");
    },
    EventTypeOptions: function () {
      console.log("watch EventTypeOptions");
      this.EventTypeOptionsLoading = false;
    },
    StateIdOptions: function () {
      console.log("watch StateIdOptions");
      this.LoadingFlag = false;
      this.StateIdOptionsLoading = false;
      var rows = this.rows;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log({ n1 });
      if (n1 > 0) {
        var StateCode = rows.StateId;
        console.log({ StateCode });
        this.StateId = StateCode;
      }
    },
    StateId: function () {
      console.log("watch StateId");
      this.getDistrictIdOptions();
    },
    DistrictIdOptions: function () {
      console.log("watch DistrictIdOptions");
      this.LoadingFlag = false;
      this.DistrictIdOptionsLoading = false;
      var rows = this.rows;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log({ n1 });
      if (n1 > 0) {
        var DistrictCode = rows.DistrictId;
        console.log({ DistrictCode });
        var idx = this.DistrictIdOptions.map((e) => e.value).indexOf(
          DistrictCode
        );
        console.log("idx=" + idx);
        this.DistrictId = idx >= 0 ? DistrictCode : "";
      }
    },
    DistrictId: function () {
      console.log("watch DistrictId");
      this.getCityIdOptions();
    },
    CityIdOptions: function () {
      console.log("watch CityIdOptions");
      this.LoadingFlag = false;
      this.CityIdOptionsLoading = false;
      var rows = this.rows;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log({ n1 });
      if (n1 > 0) {
        var CityCode = rows.CityId;
        console.log({ CityCode });
        var idx = this.CityIdOptions.map((e) => e.value).indexOf(CityCode);
        console.log("idx=" + idx);
        this.CityId = idx >= 0 ? CityCode : "";
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      //   this.resetForm();
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "events_bidding",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getPageData() {
      console.log("getPageData called");
      var yearCode = this.JciYearCode;
      console.log({ yearCode });
      this.getEventTypeOptions();
      this.getStateIdOptions();

      // get participant zone options
      this.ParticipantZoneCode = "";
      this.ParticipantZoneCodeOptionsLoading = true;
      this.getZoneCodeOptions(
        "ParticipantZoneCode",
        "ParticipantZoneCodeOptions",
        {
          UserInterface: 2,
          YearCode: yearCode,
        }
      );

      // get host zone options

      this.HostZoneCode = "";
      this.HostZoneCodeOptionsLoading = true;
      this.getZoneCodeOptions("HostZoneCode", "HostZoneCodeOptions", {
        UserInterface: 2,
        YearCode: yearCode,
      });

      // get NGB designation options

      this.NationalDesigntion = "";
      this.NationalDesigntionOptionsLoading = true;
      this.getDesignationOptions(
        "NationalDesigntion",
        "NationalDesigntionOptions",
        {
          UserInterface: 4,
          OrganizationTypeId: 1,
          CategorySubName: "designation_national",
        }
      );

      // get ZGB designation options

      this.ZoneDesigntion = "";
      this.ZoneDesigntionOptionsLoading = true;
      this.getDesignationOptions("ZoneDesigntion", "ZoneDesigntionOptions", {
        UserInterface: 4,
        OrganizationTypeId: 2,
        CategorySubName: "designation_zone",
      });

      // get LGB designation options

      this.LomDesigntion = "";
      this.LomDesigntionOptionsLoading = true;
      this.getDesignationOptions("LomDesigntion", "LomDesigntionOptions", {
        UserInterface: 4,
        OrganizationTypeId: 3,
        CategorySubName: "designation_lo",
      });

      // get Committee designation options

      this.CommitteeDesigntion = "";
      this.CommitteeDesigntionOptionsLoading = true;
      this.getDesignationOptions(
        "CommitteeDesigntion",
        "CommitteeDesigntionOptions",
        {
          UserInterface: 4,
          OrganizationTypeId: 1,
          CategorySubName: "designation_national_committee",
        }
      );
    },
    resetForm() {
      //   this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.$refs.form3.reset();
      this.$refs.form4.reset();
    },
    getEventTypeDetails() {
      console.log("getEventTypeDetails called");
      this.AllMembers = [];
      var temp_code = this.EventType;
      console.log("temp_code=" + temp_code);
      var idx = this.EventTypeOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var item = this.EventTypeOptions[idx];
        console.log({ item });
        this.SelectedEventType = item;
      }
    },
    getZoneCodeOptions(source, destination, request) {
      console.log("getZoneCodeOptions called");
      if (source != "" && destination != "") {
        var selectbox1_url = "api/year-wise-lom/zone-options";
        this.getSelectBoxOptions(source, destination, request, selectbox1_url);
      }
    },
    getDesignationOptions(source, destination, request) {
      console.log("getDesignationOptions called");
      if (source != "" && destination != "") {
        var selectbox1_url = "api/designation/options";
        this.getSelectBoxOptions(source, destination, request, selectbox1_url);
      }
    },
    getEventTypeOptions() {
      console.log("getEventTypeOptions called");
      this.EventType = "";
      this.EventTypeOptionsLoading = true;
      var selectbox1_source = "EventType";
      var selectbox1_destination = "EventTypeOptions";
      var selectbox1_url = "api/jci-program-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getStateIdOptions() {
      console.log("getStateIdOptions called");
      this.LoadingFlag = true;
      this.StateIdOptionsLoading = true;
      var selectbox1_source = "StateId";
      var selectbox1_destination = "StateIdOptions";
      var selectbox1_url = "api/states/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDistrictIdOptions() {
      console.log("getDistrictIdOptions called");
      var StateId = this.StateId;
      console.log({ StateId });
      if (StateId != "") {
        this.LoadingFlag = true;
        this.DistrictIdOptionsLoading = true;
        var selectbox1_source = "DistrictId";
        var selectbox1_destination = "DistrictIdOptions";
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityIdOptions() {
      console.log("getCityIdOptions called");
      var DistrictId = this.DistrictId;
      if (DistrictId != "") {
        this.LoadingFlag = true;
        this.CityIdOptionsLoading = true;
        var selectbox1_source = "CityId";
        var selectbox1_destination = "CityIdOptions";
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this! Kindly check the information are correct",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Continue`,
        confirmButtonColor: "#3085d6",
        cancelButtonText: `Cancel`,
        cancelButtonColor: "#d33",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.submitForm();
        } else {
          this.sweetAlert("error", "You cancelled the process", true);
        }
      });
    },
    submitForm() {
      console.log("submitForm is called");

      // Create FormData object
      let formData = new FormData();

      // Append all form data to the FormData object
      formData.append("UserInterface", 1);
      formData.append("Year", this.JciYearCode);
      formData.append("StageType", this.StageType);
      formData.append("EventName", this.EventName);
      formData.append("VenueType", this.VenueType);
      formData.append("HallCapacity", this.HallCapacity);
      formData.append("TrainDistance", this.TrainDistance);
      formData.append("AvailableRooms", this.AvailableRooms);
      formData.append("BusDistance", this.BusDistance);
      formData.append("AirportDistance", this.AirportDistance);
      formData.append("FromDate", this.FromDate);
      formData.append("LgbHeldDate", this.LgbHeldDate);
      formData.append("ToDate", this.ToDate);
      formData.append("UploadedFile1", this.UploadedFile1);
      formData.append("EligibilityResolution", this.EligibilityResolution);
      formData.append("Venue", this.Venue);
      formData.append("CityName", this.CityName);
      formData.append("Remarks", this.Remarks);

      console.log("formData: ", formData);

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var add_url = server_url + "api/events/bidding/store";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      this.SubmitFlag = true;
      this.OverlayLoadingFlag = true;

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;
      this.$http({
        url: add_url,
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SubmitFlag = false;
          thisIns.OverlayLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);
          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.$router.push("/events-bidding/lo-list");
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SubmitFlag = false;
          thisIns.OverlayLoadingFlag = false;
        });
    },
  },
  beforeMount() {
    console.log("jci-program-create beforeMount");
    var ZoneName = this.$session.get("ZoneName");
    ZoneName = ZoneName == (null || undefined) ? "" : ZoneName;
    console.log({ ZoneName });
    this.ZoneName = ZoneName;

    var ZoneCode = this.$session.get("ZoneId");
    ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
    console.log({ ZoneCode });
    this.ZoneCode = ZoneCode;

    var LomCode = this.$session.get("LomId");
    LomCode = LomCode == (null || undefined) ? "" : LomCode;
    console.log({ LomCode });
    this.LomCode = LomCode;

    var LomName = this.$session.get("LomName");
    LomName = LomName == (null || undefined) ? "" : LomName;
    console.log({ LomName });
    this.LomName = LomName;

    var CurrentDesignation = this.$session.get("CurrentDesignation");
    CurrentDesignation =
      CurrentDesignation == (null || undefined) ? "" : CurrentDesignation;
    console.log({ CurrentDesignation });
    this.CurrentDesignation = CurrentDesignation;

    var MemeberFullname = this.$session.get("MemeberFullname");
    MemeberFullname =
      MemeberFullname == (null || undefined) ? "" : MemeberFullname;
    console.log("MemeberFullname=" + MemeberFullname);
    this.MemeberFullname = MemeberFullname;

    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#jci-program-create {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-card__subtitle,
  .v-card__text {
    font-size: 16px;
  }

  .v-list-item--dense .v-list-item__title,
  .v-list-item--dense .v-list-item__subtitle,
  .v-list--dense .v-list-item .v-list-item__title,
  .v-list--dense .v-list-item .v-list-item__subtitle {
    font-size: 18px !important;
  }
  .mycard-text1 {
    font-family: sans-serif;
    // text-transform: capitalize;
  }
  .fsize-3 {
    font-size: 16px !important;
  }
}
</style>